// import Vue from 'vue'
import i18n from './i18n'

export function i18nStatus(value,list){
	// console.log('i18n',i18n);
	let locale = i18n._vm.locale;
	// let locale = l;
	let findItem = list.find(item=>{
		return value==item.value
	});
	if('en'==locale){
		return findItem.label_en;
	} else {
		return findItem.label;
	}
}
