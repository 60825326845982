<template>
  <!-- <div> -->
    <!-- <el-cascader
      :value="value"
      :size="size"
      :options="options"
      :props="props"
      :disabled="disabled"
      :placeholder="placeholder"
      separator=" > "
      filterable
      :clearable="clearable"
      :change-on-select="changeOnSelect"
      @change="change"
      @remove-tag="removeTag"
      @clearCheckedNodes="clearCheckedNodes"
      :style="{width: width+'px'}">
    </el-cascader> -->
		<el-select 
			clearable
			filterable 
			v-model="value" 
			:placeholder="$t('FormMsg.Please_select')" 
			:size="size"
			:style="{width: width+'px'}"
			@change="change">
			<el-option v-for="(item,index) in list" :key="index" :label="item.cusWhName" :value="item.userId"></el-option>
		</el-select>
</template>
<script>
// import Vue from 'vue'

  // const getCascDtata = (scale,code) => {
  //   return Vue.prototype.$http
  //     .put(Vue.prototype.$urlConfig.HyCommodityCateList, {
  //       parentId: code,
  //       scale: scale
  //     });
  // }
export default {
  // name: 'HyCityCountyLinkage',

  // props: ['initLinkageData', 'sizeAll', 'gutter', 'placeholderprefix', 'hideShowL2', 'hideShowL3','value','changeOnSelect'],
  props: {
    // openDate:"",
    // initLinkageData:{
    //   default: function() {
    //     return {}
    //   },
    //   type: Object
    // },
    // props:{
    //   default: function() {
    //     return {
    //       value: 'id',
    //       label: 'subjectName',
    //       children: 'nexts',
    //       expandTrigger: 'hover',
    //     }
    //   },
    //   type: Object
    // },
    // placeholder:{
    //   default: function() {
    //     return this.$t('i18nn_470bbdcc42b395ec')
    //   },
    //   type: String
    // },
    width:{
      default: function() {
        return 150
      },
      type: Number
    },
    size:{
      default: function() {
        return 'small'
      },
      type: String
    },
    // gutter:{
    //   default: function() {
    //     return ''
    //   },
    //   type: String
    // },
    // value:{
    //   default: function() {
    //     return ""
    //   },
    //   type: String
    // },
    // changeOnSelect:{
    //   default: function() {
    //     return false
    //   },
    //   type: Boolean
    // },
    // clearable:{
    //   default: function() {
    //     return true
    //   },
    //   type: Boolean
    // },
    // disabled:{
    //   default: function() {
    //     return false
    //   },
    //   type: Boolean
    // },
    //可选的层级，下拉展示的层级
    // showLevel:{
    //   default: function() {
    //     return 3
    //   },
    //   type: Number
    // },
  },
  data() {
    return {
      // spanSize: 8,
      // size: '',
      // plaprefix: '',
      // gutter_show: 0,

      // value: [],
			value:"",
      list: [],
			loading:false,
    
    };
  },
  // watch: {
  //   value: function(val1, val2) {
  //     console.log("watch cas value",arguments);
  //     // this.getListData_1();
  //     // this.init();
  //   }
  //   // initLinkageData: function(val1, val2) {
  //   //   // this.getListData_1();
  //   //   this.init();
  //   // }
  // },
  //创建时
  created() {
    this.init();
  },
  //编译挂载前
  mounted() {
    // console.log("mounted");
  },
  methods: {
    init() {
      // this.props = Object.assign(this.myProps, this.props)
      this.getListData();

    },
		initData(val){
			if(val){
				this.value = val;
			} else {
				this.value = '';
			}
		},
    //值改变
    change(v){
      console.log('change', v);
      this.$emit('change', v);
    },
    // removeTag(v){
    //   console.log("removeTag",v);
    //   this.$emit('removeTag', v);
    // },
    // clearCheckedNodes(v){
    //   console.log("clearCheckedNodes",v);
    //   this.$emit('clearCheckedNodes', v);
    // },



    //信息
    getListData() {
      // let _this = this;
      // this.allLinkageData.data_1_Loading = true;
      this.loading = true;
      this.$http
        .put(this.$urlConfig.agentUserQueryMy, {
          // parentId: null,
          // scale: 1
        })
        .then(({ data }) => {
          // console.log(this.$t('i18nn_3401b0539851b242'));
          console.log(data);
          this.loading = false;
          if (200 == data.code) {
            // this.selectOption['ListData_1'] = data.rows;
            // let options = data.rows;
            // options.forEach(item => {
            //   item.children = [];
            // });

            let list = data.rows;
            // if(1===this.showLevel){
            //   options.forEach(item => {
            //     item.nexts = null;
            //   });
            // } else if(2===this.showLevel){
            //   options.forEach(item => {
            //     item.nexts.forEach(item2 => {
            //       item2.nexts = null;
            //     });
            //   });
            // }
						
            this.list = list.filter(item=>{
							return !!item.cusWhName
						});
          } else {
            // this.$message({
            //   message: '商品类目请求失败!',
            //   type: 'warning'
            // });
          }
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
          console.log(this.$t('i18nn_758239e08104dfb8'));
          // this.$message({
          //   message: this.$t('i18nn_758239e08104dfb8'),
          //   type: 'warning'
          // });
        });
      // }
    },

  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
